import * as React from 'react';
import { Link } from 'react-router-dom';
import { AuthRedirectWrapper } from 'components';
import { routeNames } from 'routes';

const HomePage = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='row w-100'>
        <div className='col-12 col-md-10 col-lg-6 mx-auto m-auto'>
          <div className='shadow-sm rounded p-4 border-0'>
            <div className='text-center'>
              <Link
                to={routeNames.unlock}
                className='btn mt-3 text-white'
                data-testid='loginBtn'
              >
                <img className='arcadeMachine' alt={'machine'} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Home = () => (
  <AuthRedirectWrapper>
    <HomePage />
  </AuthRedirectWrapper>
);
