import React from 'react';
import { ReactComponent as HeartIcon } from '../../../assets/img/heart.svg';

export const Footer = () => {
  return (
    <footer className='bg-footer text-center pt-1 pb-2'>
      <div>
        <a
          {...{
            target: '_blank'
          }}
          className='bg-footer bg-footer-link d-flex align-items-center'
          href='https://superrarebears.com/'
        >
          Lovingly <HeartIcon className='mx-1' /> crafted by SuperRareBears.
        </a>
      </div>
    </footer>
  );
};
