import * as React from 'react';
import { useEffect, useState } from 'react';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions/useGetPendingTransactions';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks/useGetNetworkConfig';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { FormatAmount } from '@multiversx/sdk-dapp/UI/FormatAmount';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import axios from 'axios';
import { contractAddress } from 'config';

interface State {
  status: 'none' | 'done' | 'authenticating' | 'error';
  message?: string;
}

export const TopInfo = () => {
  const { address, account } = useGetAccountInfo();
  const [state, setState] = useState<State>({ status: 'none', message: '' });
  const [loading, setLoading] = useState(false);
  const { hasPendingTransactions } = useGetPendingTransactions();
  const /*transactionSessionId*/ [, setTransactionSessionId] = useState<
      string | null
    >(null);
  const [wasClicked, setWasClicked] = useState(false);
  const {
    network: { apiAddress }
  } = useGetNetworkConfig();
  const domain =
    'https://pandamonium.superrarebears.com/prod/samurai-rampage/20221215/index.html';
  const xoxno = 'https://api.xoxno.com/getDelegatorsForPoolId';
  const encodedAddressBase64 = Buffer.from(address).toString('base64');
  const encodedAddressHex = Buffer.from(encodedAddressBase64).toString('hex');

  const sendRareTransaction = async () => {
    setWasClicked(true);
    const yesTransaction = {
      value: '0',
      data: 'ESDTTransfer@524152452d393965386230@0e5d9d1ff846840000',
      receiver:
        'erd1l3xnkmxhwfa2t668sdha7lzlmvqukawj54qrxvze4fj2tq47fkhse6pr08',
      gasLimit: '550000'
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: yesTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing RARE fee transaction',
        errorMessage: 'An error has occurred during paying RARE fee',
        successMessage: 'RARE fee transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      axios
        .get(
          `${apiAddress}/accounts/${address}/transactions?status=success&receiver=erd1l3xnkmxhwfa2t668sdha7lzlmvqukawj54qrxvze4fj2tq47fkhse6pr08&token=RARE-99e8b0&after=1718982448`,
          {
            timeout: 8000
          }
        )
        .then((response1) => {
          if (response1?.data.length > 0) {
            if (
              response1?.data[0]['data'] ==
              'RVNEVFRyYW5zZmVyQDUyNDE1MjQ1MmQzOTM5NjUzODYyMzBAMGU1ZDlkMWZmODQ2ODQwMDAw'
            ) {
              setState({
                status: 'done'
              });
            } else {
              setState({
                status: 'error',
                message: 'You cannot play!'
              });
            }
          } else {
            setState({
              status: 'error',
              message: 'You cannot play!'
            });
          }
        })
        .catch(() => {
          setState({
            status: 'error',
            message: 'Something went wrong! Please refresh the page!'
          });
        })
        .finally(() => setLoading(false));
    } catch (err) {}
  }, [address, hasPendingTransactions]);

  return (
    <div className='text-white' data-testid='topInfo'>
      <div className='mb-1'>
        <span className='opacity-6 mr-1'>Your address:</span>
        <span data-testid='accountAddress'> {address}</span>
      </div>
      {loading && <div className='spinner-border' />}
      {state.status === 'authenticating' && (
        <span>
          <div className='h3 mt-5'>
            <img className='cannotPlay' alt='cannot play' />
          </div>
          <div className='h3 mt-4'>{state.message}</div>
        </span>
      )}
      {state.status === 'error' && (
        <span>
          <div className='h3 mt-5'>
            <img className='cannotPlay' alt='cannot play' />
          </div>
          <div className='h3 mt-4'>{state.message}</div>
          <div className='h5 mt-4'>
            <p className='mt-2 mb-5'>
              Please make sure you've paid the 265 $RARE fee
            </p>
            <button
              type='button'
              className='inline-block rounded-lg px-3 py-2 text-center'
              disabled={hasPendingTransactions || wasClicked}
              onClick={sendRareTransaction}
            >
              PAY 265 $RARE fee
            </button>
            <br />
            <p className='mt-3'>Tx not working? Send 265 $RARE to wallet:</p>
            <p>
              erd1l3xnkmxhwfa2t668sdha7lzlmvqukawj54qrxvze4fj2tq47fkhse6pr08
            </p>
            <p>Herotag: srbdistribution</p>
            <div className='h5 mt-5'>
              <p className='mt-2'></p>
              <h3 className='mb-3'>
                <b>Prizes</b>
              </h3>
              <p>🥇35% of $RARE from entries + HYPEY + Portal</p>
              <p>🥈20% of $RARE from entries + Portal</p>
              <p>🥉10% of $RARE from entries + Portal</p>
              <br />
              <p>🎖3 random winners share 15%</p>
              <p>🎮Bonus 5x portals for 5 random players</p>
              <p>20% of $RARE burns🔥</p>
              <br />
              <p>Min score and applicants applies</p>
              <p>Runs for 7 days</p>
            </div>
          </div>
        </span>
      )}
      {state.status === 'done' && (
        <span>
          <div className='h3 mt-5'>
            <img className='cannotPlay' alt='cannot play' />
          </div>
          <div className='h3 mt-4'>
            <strong>SAMURAI PANDAMONIUM</strong>
          </div>
          <div className='h3 mt-4'>
            <strong>RARELICIOUS TOURNAMENT</strong>
          </div>
          <div className='h5 mt-5'>
            <p className='mt-2'></p>
            <h3 className='mb-3'>
              <b>Prizes</b>
            </h3>
            <p>🥇35% of $RARE from entries + HYPEY + Portal</p>
            <p>🥈20% of $RARE from entries + Portal</p>
            <p>🥉10% of $RARE from entries + Portal</p>
            <br />
            <p>🎖3 random winners share 15%</p>
            <p>🎮Bonus 5x portals for 5 random players</p>
            <p>20% of $RARE burns🔥</p>
            <br />
            <p>Min score and applicants applies</p>
            <p>Runs for 7 days</p>
          </div>
          <a
            href={`${domain}?nocache=true&token=${encodedAddressHex}`}
            className='btn mt-5'
            target='_blank'
          >
            <img className='startButton' alt='start button' />
          </a>
        </span>
      )}
    </div>
  );
};
